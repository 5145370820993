import { Button, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useState } from 'react';
import '../assets/css/Login.css';

function Login({ setToken }) {

    const [formLogin] = Form.useForm();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    async function requestToken(credentials) {
        axios.post("https://liga-taca-isep-api.herokuapp.com/api/auth/signin",
            {
                username: credentials.username,
                password: credentials.password
            })
            .then(response => {
                if (response.data.token) {
                    setToken(response.data.token);
                }
            })
            .catch((error) => {
                message.error("Credenciais inválidas!")
                formLogin.resetFields();
                console.warn(error);
            })
    }

    const onSubmit = async () => {

        await requestToken(
            {
                username,
                password
            }
        );
    }

    return (
        <div className="login-wrapper">
            <Form
                form={formLogin}
                name="login-form"
                className="login-form"
                onFinish={onSubmit}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Obrigatório!' }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username"
                        onChange={e => setUsername(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Obrigatório!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                </Form.Item>

                <Form.Item className="login-form-wrapper">
                    <Button type="default" htmlType="submit">
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login;