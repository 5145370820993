import React, { useEffect, useState } from 'react';
import '../assets/css/Schedules.css';
import 'antd/dist/antd.css';
import axios from 'axios';
import { Button, Empty, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiFootball } from 'react-icons/bi';
import { MdAccessTime } from 'react-icons/md';
import { TbLetterG, TbLetterF } from 'react-icons/tb';


async function fetchPlayerData(id) {
  return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/${id}`);
}

async function fetchRoundData(id) {
  return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/${id}`);
}

function Schedules() {
  const [dataSource, setDataSource] = useState([]);
  const [dates, setDates] = useState([]);
  const [platform, setPlatform] = useState('PC');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [headerInfo, setHeaderInfo] = useState([
    'Equipa 1',
    'Resultado',
    'Equipa 2',
    'Data/Hora do Jogo',
    'Grupo',
    'QUARTOS DE FINAL',
    'MEIA FINAL',
    'FINAL',
    '',
  ]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://liga-taca-isep-api.herokuapp.com/api/games/active/${platform}`)
      .then(async (response) => {
        setError(false);
        const dates = [];
        const scheduledGames = await Promise.all(
          response.data
            .filter(game => game.status === 0)
            .sort((game1, game2) => new Date(game1.dateHour) - new Date(game2.dateHour))
            .map(async (game) => {
              const player1 = (await fetchPlayerData(game.playerId1)).data.name;
              const player2 = (await fetchPlayerData(game.playerId2)).data.name;

              const p1Name = player1.split(' ');
              const p2Name = player2.split(' ');

              const [date, time] = new Date(game.dateHour).toLocaleString().split(',');
              const [hours, minutes] = time.split(':');

              const round = (await fetchRoundData(game.roundId)).data.number;

              /* have to use this find instead of includes because we're saving
              entire dateTime but only want to compare date */
              if (dates.find(element => element.split('T')[0] === game.dateHour.split('T')[0]) === undefined) {
                dates.push(game.dateHour);
              }
              return {
                key: game.id,
                player1: `${player1}`,
                player2: `${player2}`,
                round: round,
                date: date,
                time: `${hours.trim()}:${minutes}`
              }

            })
        );
        setDates(dates);
        setDataSource(scheduledGames);
      })
      .catch((error) => {
        setError(true);
        setDates([]);
        setDataSource([]);
        console.error(`An error has ocurred: ${error}`);
      })
      .finally(() => {
        if (error) {
          message.error({
            content: 'Não há informação disponível de momento. Por favor, tente mais tarde!'
          })
        }
        /* error message will not appear if this one
        is not here so we made it invisible
        :))))))))))))))))))))))))))))))))))) */
        message.error({
          content: '-',
          className: "hidden-message"
        });
        setLoading(false);
      })
  }, [platform, error]);

  return (
    <div id="schedules">
      {
        !loading &&
        <div className="btn-group centered-btn-group">
          <Button className="sm-pc-btn" shape="circle" size="large" onClick={() => setPlatform("PC")} tabIndex="-1">
            <TbLetterG size="36px" />
          </Button>
          <Button className="sm-ps-btn" shape="circle" size="large" onClick={() => setPlatform("PS")} tabIndex="-1">
            <TbLetterF size="36px" />
          </Button>
        </div>
      }
      {loading && <LoadingOutlined className="spin-icon spin-icon-enhancer" spin />}
      {
        !loading && !!dataSource.length &&
        <div id="container">
          {
            /* for some reason, if we don't order the dates here
            they will not keep their insertion order */
            dates
              .sort((date1, date2) => new Date(date1) - new Date(date2))
              .map((date) => (
                <div key={date}>
                  <h1 key={date} className="bold-text game-date">{new Date(date).toLocaleString().split(',')[0]}</h1>
                  {
                    dataSource
                      .filter((game) => game.date === new Date(date).toLocaleString().split(',')[0])
                      .map((game) => (
                        <div key={game.key} id="match">
                          <span className="time-span bold-text"><MdAccessTime size="16px" />{game.time}</span>
                          <span className="players-span regular-text">{game.player1}</span>
                          <span className="ball-span"><BiFootball size="32px" /></span>
                          <span className="players-span regular-text">{game.player2}</span>
                          <span className="help-span bold-text">
                              {game.round<100 && 
                                headerInfo[4]
                              }
                              {game.round<100 && 
                                game.round
                              }                
                              {game.round===101 ? 
                                (headerInfo[5]) :
                                (headerInfo[8])
                              }                
                              {game.round===102 ? 
                                (headerInfo[6]) :
                                (headerInfo[8])
                              }
                              {game.round===103 ? 
                                (headerInfo[7]) :
                                (headerInfo[8])
                              } 
                            </span>
                        </div>
                      ))
                  }
                </div>
              ))
          }
        </div>
      }
      {
        !loading && !dataSource.length &&
        <div id="container">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Escolhe G para ver os jogos da fase de Grupos e F para os da fase Final!" />
        </div>
      }
    </div>
  );
}

export default Schedules;