import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import '../../../assets/css/Admin.css';
import { Form, Input, message, Button, Select, List } from 'antd';
import { storage } from '../../../firebase/index.js'

import axios from "axios";
import { RiComputerLine } from 'react-icons/ri';
import { FaPlaystation } from 'react-icons/fa';
const { Option } = Select;

const numb=[1, 2, 4];

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

async function fetchPlayersByPlatform(platform) {
    return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}`);
}

function CreatePlayer() {
    const [edition, setEdition] = useState("");
    const [allPCPlayers, setAllPCPlayers] = useState([]);
    const [allPSPlayers, setAllPSPlayers] = useState([]);
    const [update, setUpdate] = useState(true);
    const [file, setFile] = useState("");
    const [formJogadores] = Form.useForm();

    const token = sessionStorage.getItem('token');

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    function handlePlatform(platform) {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/editions/active/${platform}`).then((response) => {
                setEdition(response.data)
            })
            .catch((error) => {
                message.error("Não há edição ativa!");
                console.error(error);
            })
    };

    const handleSelectedFile = (e) => {
        setFile(e.target.files[0]);
    }

    const onFinish = (values) => {

        const player = {
            name: values.name,
            grupo: values.grupo,
            degree: values.degree,
            imageUrl: file.name,
            editionId: edition.id
        }

        axios.post(`https://liga-taca-isep-api.herokuapp.com/api/players`,
            player,
            config
        )
            .then((response) => {
                const uploadTask = storage.ref(`${file.name}`).put(file);
                uploadTask.on(
                    "state_changed",
                    snapshot => { },
                    error => {
                        console.log(error)
                    },
                    () => {
                        storage.ref("").child(file.name).getDownloadURL().then(url => {
                            console.log(url)
                        })
                    }
                )
                formJogadores.resetFields();
                setUpdate(true);
                message.success('Jogador criado com sucesso!');
            }, (error) => {
                message.error('Ocorreu um erro a criar o jogador!');
                console.log(error);
            });
    };

    useEffect(() => {
        /* javardo but gotta hurry up */
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/PC`)
            .then((response) => {
                setAllPCPlayers(response.data.map((player) => {
                    return {
                        name: player.name,
                        grupo: player.grupo,
                        degree: player.degree
                    }
                }))
            })
            .catch((error) => {
                console.error(error);
            })
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/PS`)
            .then((response) => {
                setAllPSPlayers(response.data.map((player) => {
                    return {
                        name: player.name,
                        grupo: player.grupo,
                        degree: player.degree
                    }
                }))
            })
            .catch((error) => {
                console.error(error);
            })
        setUpdate(false);
    }, [update])

    return (
        <div className="centered-form">
            < Form className="max-width" {...layout} form={formJogadores} name="control-hooks" onFinish={onFinish} >
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É preciso selecionar uma Fase!' }]}>
                    <Select
                        placeholder="Selecionar Fase"
                        allowClear
                        onChange={handlePlatform}
                    >
                        <Option value="PC">Fase Grupos</Option>
                        <Option value="PS">Fase Final</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="name" label="Nome Equipa" rules={[{ required: true, message: 'É preciso adicionar o nome da equipa!' }]}>
                <Input />
                </Form.Item>
                <Form.Item name="grupo" label="Grupo Equipa" rules={[{ required: true, message: 'É preciso adicionar grupo!' }]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item name="degree" label="info adicional" rules={[{ required: true, message: 'É preciso adicionar dados do jogador!' }]}>
                    
                    <Select
                        placeholder="Selecionar dados da equipa"
                        allowClear
                    >
                         <Option value="7 elementos">
                            7 elementos
                            </Option>
                         <Option value="8 elementos">
                            8 elementos
                            </Option>
                        <Option value="9 elementos">
                            9 elementos
                            </Option>
                        <Option value="10 elementos">
                            10 elementos
                            </Option>
                        <Option value="11 elementos">
                            11 elementos
                            </Option>
                        <Option value="12 elementos">
                            12 elementos
                            </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="image" label="Print dos elementos" rules={[{ required: true, message: 'É preciso importar uma fotografia!' }]}>
                    <input type="file" name="file" onChange={handleSelectedFile} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Adicionar Equipa
                </Button>
                </Form.Item>
            </Form >
            <p className="disclaimer-text">
                <span className="bold-text">Edição ativa: </span>
                <span className="regular-text">{edition.name}</span>
            </p>
            <div className="editions-lists max-width">
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Jogadores - Fase Grupos <RiComputerLine size="24px" /></span>}
                    bordered
                    dataSource={allPCPlayers}
                    renderItem={player => <List.Item className="regular-text">{player.name} - Grupo: {player.grupo} - {player.degree}</List.Item>}
                />
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Jogadores - Fase Final <FaPlaystation size="24px" /></span>}
                    bordered
                    dataSource={allPSPlayers}
                    renderItem={player => <List.Item className="regular-text">{player.name} - Grupo: {player.grupo} - {player.degree}</List.Item>}
                />
            </div>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    );
}

export default CreatePlayer;
