import firebase from "firebase/app";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCd0VSVyiCPnmDne-axH3MMjjkOMwZ7m28",
    authDomain: "liga-taca-isep.firebaseapp.com",
    projectId: "liga-taca-isep",
    storageBucket: "liga-taca-isep.appspot.com",
    messagingSenderId: "1040293606233",
    appId: "1:1040293606233:web:62eb0e36b4ec45588d5248",
    measurementId: "G-PYJPY11ZHY"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };