import React from 'react';

import '../assets/css/Forms.css';

function Inscricoes() {
    return (
        <iframe className="forms"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdBSpxvQ1Jf-jFc_ID3zDbonAoh2oC0NOtkBz56819x7fSVbg/viewform?embedded=true"
            width="640" height="399" frameBorder="0" marginHeight="0" marginWidth="0">A carregar…</iframe>
    );
}

export default Inscricoes;