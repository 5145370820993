import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Form, DatePicker, message, Button, Select } from 'antd';
import axios from "axios"

const { Option } = Select;

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

function CreateGame() {
    const [form] = Form.useForm();

    const [platform, setPlatform] = useState('');
    const [dateHour, setDateHour] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [players, setPlayers] = useState([]);
    const [player1, setPlayer1] = useState("");
    const [player2, setPlayer2] = useState("");

    const token = sessionStorage.getItem('token');

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    function handlePlatform(platform) {
        setPlatform(platform)
    };

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}`).then((response) => {
                const players = response.data;
                setPlayers(players.map((player) => ({ name: player.name, id: player.id })))
            })
            .catch((error) => {
                if (platform !== '') {
                    setPlayers([]);
                    message.error("Não há jogadores na edição ativa!");
                    console.error(error);
                }
            })
    }, [platform]);

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/active/${platform}`).then((response) => {
                const rounds = response.data;
                setRounds(rounds.map((round) => ({ number: round.number, id: round.id })))
            })
            .catch((error) => {
                if (platform !== '') {
                    setRounds([]);
                    message.error("Não há jornadas na edição ativa!");
                    console.error(error);
                }
            })
    }, [platform]);

    function onOk(value) {
        setDateHour(value.seconds(0).milliseconds(0).toISOString());
    }

    function handlePlayer1(e) {
        removeObjectFromArray(true, e);
    }
    function handlePlayer2(e) {
        removeObjectFromArray(false, e);
    }

    const removeObjectFromArray = (flag, objectCode) => {
        let i;
        let player;
        for (const [key, value] of Object.entries(players)) {
            if (value.name === objectCode) {
                if (flag) {
                    player = player1;
                    setPlayer1(value)
                } else if (!flag) {
                    player = player2;
                    setPlayer2(value)
                }
                i = key;
                break;
            }
        }
        let temp = [...players];
        temp.splice(i, 1);
        if (player !== "") {
            temp.push({ name: player })
        }
        setPlayers(temp)
    }

    const onFinish = (values) => {
        form.resetFields();

        setRounds([]);
        setPlayers([]);
        setPlayer1("");
        setPlayer2("");

        const game = {
            playerId1: player1.id,
            playerId2: player2.id,
            roundId: values.jornada,
            dateHour: dateHour
        }
        console.log(game)
        axios.post(`https://liga-taca-isep-api.herokuapp.com/api/games`,
            game,
            config
        )
            .then((response) => {
                message.success('Jogo criado com sucesso!');
                console.log(response);
            }, (error) => {
                message.error('Ocorreu um erro a criar o jogo!');
                console.log(error);
            });
    };

    return (
        <div className="centered-form">
            <Form className="max-width" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É preciso selecionar uma Fase!' }]}>
                    <Select
                        placeholder="Selecionar Fase"
                        allowClear
                        onChange={handlePlatform}
                    >
                        <Option value="PC">Fase Grupos</Option>
                        <Option value="PS">Fase Final</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="jogador1" label="Jogador 1" rules={[{ required: true, message: 'É preciso selecionar um jogador!' }]}>
                    <Select
                        placeholder="Selecionar a primeira equipa"
                        onChange={handlePlayer1}
                    >
                        {players.map((player) => (
                            <Option value={player.name}>
                                {player.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="jogador2" label="Jogador 2" rules={[{ required: true, message: 'É preciso selecionar um jogador!' }]}>
                    <Select
                        placeholder="Selecionar a segunda equipa"
                        onChange={handlePlayer2}
                    >
                        {players.map((player) => (
                            <Option key={player.id} value={player.name}>
                                {player.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="jornada" label="Grupo" rules={[{ required: true, message: 'É preciso selecionar um grupo!' }]}>
                    <Select
                        placeholder="Selecionar o grupo"
                    >
                        {rounds.map((round) => (
                            <Option value={round.id}>
                                {round.number}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="data" label="Data do Jogo" rules={[{ required: true, message: 'É preciso selecionar uma data!' }]}>
                    <DatePicker showTime={{ format: 'HH:mm' }} onChange={onOk} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Adicionar Jogo
                    </Button>
                </Form.Item>
            </Form>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    );
}

export default CreateGame;