import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import '../assets/css/Players.css';
import axios from "axios"
import { storage } from '../firebase/index.js'
import { Button, Card, message } from 'antd';
import { RiComputerLine } from 'react-icons/ri';
import { FaPlaystation } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';

const { Meta } = Card;

async function fetchImage(fileName) {
    return await storage.ref("").child(`${fileName}`).getDownloadURL();
}

function Players() {
    const [dataSource, setDataSource] = useState([]);
    const [platform, setPlatform] = useState('PC');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    // useEffect(() => {
    //     document.getElementsByClassName("layout-content").item(0).classList.add("background-enhancer");
    // }, []);

    const playersList = [
        {
            team: "Schumickel",
            players: [
                "Miguel Shu",
                "Dinis Silva",
                "Cauê Guimarães",
                "Pedro Santos",
                "Gonçalo Gomes",
                "Miguel Cruz",
                "Nuno Barbosa",
                "João Silva",
                "Bruno Miguel",
                "Martim Serra",
                "Dinis Pereira",
                "Vítor Santos"
            ]
        },
        {
            team: "Morcela FC",
            players: [
                "Carlos Moreira",
                "Diogo Sousa",
                "Pedro Moreira",
                "Ricardo Barroso",
                "João Aguiar",
                "Gonçalo Oliveira",
                "Gabriel Pinto",
                "André Coelho",
                "Rafael Pereira"
            ]
        },
        {
            team: "Real Maracujá",
            players: [
                "Guilherme Gouveia",
                "Carlos Oliveira",
                "Gonçalo Breda",
                "Filipe José",
                "João Maia",
                "Rui Pedro",
                "Guilherme Saladrigas",
                "Diogo Alves",
                "João Esteves",
                "Pedro Sousa",
                "Diogo Kolmer",
                "João Tomás"
            ]
        },
        {
            team: "Boda",
            players: [
                "Hélder Rocha",
                "Luís Silva",
                "Francisco Oliveira",
                "Francisco Monteiro",
                "André Martins",
                "Gonçalo Boa-Nova",
                "Alfredo Garcês",
                "Tiago Branco",
                "Diogo Vieira",
                "Francisco Silveira",
                "David Pinho",
                "Daniel Lourenço",
                "Vítor Santos"
            ]
        },
        {
            team: "Inter de Melão",
            players: [
                "Pedro Vieira",
                "Vítor Crista",
                "José Luís",
                "Diogo Correia",
                "Rui Silva",
                "António Pinho",
                "José Cunha",
                "César Ferreira",
                "Ricardo Sousa",
                "Sérgio Carreirinha",
                "Letícia Gomes"
            ]
        },
        {
            team: "Black Mamba",
            players: [
                "João Vieira",
                "Eduardo Silva",
                "Pedro Alves",
                "Roberto Nogueira",
                "Tiago Rodrigues",
                "Francisco Ribeiro",
                "Pedro Fernandes",
                "Tiago Marques",
                "Tiago Freitas",
                "Daniel Ferreira",
                "Duarte Santos",
                "Bruno Sobreira"
            ]
        },
        {
            team: "Bar sem Lona",
            players: [
                "Rúben Eiras",
                "Afonso Moreira",
                "Pedro Almeida",
                "Diogo Azevedo",
                "Henrique Babo",
                "Gonçalo Oliveira",
                "Guilherme Cunha",
                "José Ferreira",
                "João Martins",
                "Pedro moreira",
                "Rodrigo Oliveira"
            ]
        },
        {
            team: "Boda B",
            players: [
                "Pedro Costa",
                "Pedro Tabau",
                "Pedro Pereira",
                "José Pereira",
                "Gustavo Jorge",
                "João Ferreira",
                "João Có",
                "João Teixeira",
                "Carlos Soares",
                "Diogo Costa",
                "Diogo Gonçalves",
                "Rui Soares"
            ]
        },
        {
            team: "PAROLYMPIACUS",
            players: [
                "Tuna",
                "Pedrinho",
                "Ondinhas",
                "Frozone",
                "Barrote",
                "Tou Confiante",
                "Tass Bem",
                "Armamar",
                "Cuzido",
                "Cem Sola",
                "Carecona",
                "Pinante"
            ]
        },
        {
            team: "Todo contenti",
            players: [
                "Diogo Silva",
                "Francisco Leal",
                "Diogo Rio",
                "Nuno Veloso",
                "Rui Duarte",
                "Diogo Almeida",
                "Tomás Gonçalves",
                "Diogo Araújo"
            ]
        },
        {
            team: "Gaidiatog Spgings",
            players: [
                "Salvador Junqueira",
                "Rodrigo Neto",
                "Francisco Silva",
                "Diogo Carneiro",
                "Rodrigo Costa",
                "Joel Silva",
                "Luis Leites",
                "Gonçalo Lobo",
                "Simao Rua",
                "Duarte Parente",
                "Pedro Pereira"
            ]
        },
        {
            team: "Socceriri di Potencia",
            players: [
                "Diogo Meireles",
                "Cristiano Ramires",
                "Carlos Dias",
                "Gustavo Santos",
                "António Campos",
                "Gonçalo Pinho",
                "Henrique Noites",
                "Nuno Rocha",
                "Filipe Santos",
                "Miguel Queirós",
                "José Matos",
                "Pedro Bastos"
            ]
        },
        {
            team: "BBB FC (Bêbados Bons de Bola)",
            players: [
                "Terra nostra",
                "Rogerinho",
                "Gama",
                "Lil-Às",
                "Pebides",
                "Nissan",
                "Semarforo",
                "Kutchilero",
                "Sardunisca",
                "O Monte",
                "Inçoço"
            ]
        },
        {
            team: "The Last Dance",
            players: [
                "Francisco Trindade",
                "Rui Sousa",
                "Rodrigo Queirós",
                "João Barbosa",
                "Miguel Silva",
                "Rodrigo Franco",
                "Ivan Mário",
                "Leopoldo Sousa",
                "João Bastos",
                "Pedro Castro",
                "Rafael Oliveira",
                "Helder Araujo"
            ]
        }
    ];
    

    useEffect(() => {
        setLoading(true);
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}`)
            .then(async (response) => {
                setError(false);
                const players = await Promise.all(
                    response.data
                        .sort((player1, player2) => player1.name.split(' ')[0].localeCompare(player2.name.split(' ')[0]))
                        .map(async (player) => {
                            const name = player.name.split(' ');
                            const first = name[0];
                            const last = name.pop();

                            const image = await fetchImage(player.imageUrl);
                            
                            return {
                                name: player.name,
                                grupo: player.grupo,
                                info: player.degree,
                                image: image
                            }
                        }));
                setDataSource(players);
            })
            .catch((error) => {
                setError(true);
                console.error(`An error has ocurred: ${error}`);
            })
            .finally(() => {
                if (error) {
                    /* message.error({
                        content: 'Não há informação disponível de momento. Por favor, tente mais tarde!'
                    }) */
                }
                /* error message will not appear if this one
                is not here so we made it invisible
                :))))))))))))))))))))))))))))))))))) */
                message.error({
                    content: '-',
                    className: "hidden-message"
                });
                setLoading(false);
            })
    }, [platform, error]);

    
    

    return (
        <div id="players">
            <h1 className="players-header">Equipas 2024</h1>
            <div className="index-gallery">
                {loading && <LoadingOutlined className="spin-icon spin-icon-enhancer" spin />}
                {!loading &&
                    playersList.map((team, index) => (
                        <Card key={index} className="player-card">
                            <h3 className="player-card-text">{team.team}</h3>
                            {team.players.map((player, idx) => (
                                <p key={idx}>{player}</p>
                            ))}
                        </Card>
                    ))
                }
            </div>
        </div>
    );
}
export default Players;