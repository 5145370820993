import React  from 'react';
import 'antd/dist/antd.css';
import '../assets/css/Admin.css';
import { Tabs } from 'antd';
import CreatePlayer from './admin/players/CreatePlayer.js'
import Changeinfo from './admin/players/Changeinfo.js';
import CreateEdition from './admin/editions/CreateEdition.js'
import ActivateEdition from './admin/editions/ActivateEdition.js'
import CreateRound from './admin/rounds/CreateRound.js'
import CreateGame from './admin/games/CreateGame.js'
import UpdateGame from './admin/games/UpdateGame.js'
import RescheduleGame from './admin/games/RescheduleGame.js';
import Login from './Login';
import useToken from '../useToken';

const { TabPane } = Tabs;

function Admin() {

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} />
    }
    return (
        <Tabs defaultActiveKey="1" centered>
            <TabPane tab="Adicionar edição" key="1">
                <CreateEdition></CreateEdition>
            </TabPane>
            <TabPane tab="Ativar Edição" key="2">
                <ActivateEdition></ActivateEdition>
            </TabPane>
            <TabPane tab="Adicionar Equipa" key="3">
                <CreatePlayer></CreatePlayer>
            </TabPane>
            <TabPane tab="Adicionar Grupo" key="4">
                <CreateRound></CreateRound>
            </TabPane>
            <TabPane tab="Adicionar Jogo" key="5">
                <CreateGame></CreateGame>
            </TabPane>
            <TabPane tab="Atualizar Jogo" key="6">
                <UpdateGame></UpdateGame>
            </TabPane>
            <TabPane tab="Remarcar Jogo" key="7">
                <RescheduleGame></RescheduleGame>
            </TabPane>
        </Tabs>
    );
}

export default Admin;