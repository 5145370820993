import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import '../../../assets/css/Admin.css';
import { Form, Input, message, Button, Select, List } from 'antd';
import { RiComputerLine } from 'react-icons/ri';
import { FaPlaystation } from 'react-icons/fa';
import axios from "axios"
const { Option } = Select;


const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

function CreateEdition() {
    const [form] = Form.useForm();
    const [editions, setEditions] = useState([]);
    const [update, setUpdate] = useState(true);

    const token = sessionStorage.getItem('token');

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const onFinish = () => {

        const edition = {
            name: form.getFieldValue('name'),
            platform: form.getFieldValue('platform')
        }
        axios.post(`https://liga-taca-isep-api.herokuapp.com/api/editions`,
            edition,
            config
        )
            .then((response) => {
                form.resetFields();
                message.success('Edição criada com sucesso!');
                setUpdate(true);
                console.log(response);
            }, (error) => {
                message.error('Ocorreu um erro a criar a edição!');
                console.log(error);
            });
    };

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/editions`)
            .then((response) => {
                const editions = response.data
                    .map((edition) => {
                        return {
                            name: edition.name,
                            platform: edition.platform,
                            status: edition.status
                        }
                    })
                setEditions(editions);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setUpdate(false);
            })
    }, [update])

    return (
        <div className="centered-form">
            <Form className="max-width" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="name" label="Edição" rules={[{ required: true, message: 'É preciso adicionar uma edição!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É preciso selecionar uma fase!' }]}>
                    <Select
                        placeholder="Selecionar Fase"
                        allowClear
                    >
                        <Option value="PC">Fase Grupos</Option>
                        <Option value="PS">Fase Final</Option>
                    </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Adicionar Edição
            </Button>
                </Form.Item>
            </Form>
            <div className="editions-lists max-width">
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Edições - Fase Grupos <RiComputerLine size="24px" /></span>}
                    bordered
                    dataSource={editions.filter(edition => edition.platform === 'PC')}
                    renderItem={edition =>
                        edition.status === 1 ?
                            <List.Item className="active-edition regular-text">{edition.name}</List.Item> :
                            <List.Item className="regular-text">{edition.name}</List.Item>}
                />
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Edições - Fase Final <FaPlaystation size="24px" /></span>}
                    bordered
                    dataSource={editions.filter(edition => edition.platform === 'PS')}
                    renderItem={edition =>
                        edition.status === 1 ?
                            <List.Item className="active-edition regular-text">{edition.name}</List.Item> :
                            <List.Item className="regular-text">{edition.name}</List.Item>}
                />
            </div>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    );
}

export default CreateEdition;
