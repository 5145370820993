import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, message, Select } from 'antd';
import axios from 'axios';

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

const { Option } = Select;

const token = sessionStorage.getItem('token');
const config = {
    headers: { Authorization: `Bearer ${token}` }
}

async function fetchPlayerData(id) {
    return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/${id}`);
}

function RescheduleGame() {
    const [form] = Form.useForm();

    const [platform, setPlatform] = useState('');
    const [games, setGames] = useState([]);
    const [game, setGame] = useState('');

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/games/active/${platform}`)
            .then(async (response) => {
                const games = await Promise.all(
                    response.data.map(async (game) => {
                        const player1 = (await fetchPlayerData(game.playerId1)).data.name.split(' ');
                        const player1Name = player1[0] + " " + player1.pop();
                        const player2 = (await fetchPlayerData(game.playerId2)).data.name.split(' ');
                        const player2Name = player2[0] + " " + player2.pop();
                        const dateHour = new Date(game.dateHour).toLocaleString();

                        return {
                            id: game.id,
                            player1: player1Name,
                            player2: player2Name,
                            dateHour: dateHour
                        }
                    })
                );
                setGames(games);
            })
            .catch((error) => {
                if (platform !== '') {
                    setGames([]);
                    message.error('Não há jogos na edição ativa!');
                    console.error(error);
                }
            })
    }, [platform]);

    const onFinish = (values) => {
        const dateHour = new Date(values.dateHour).toISOString().split('Z')[0];
        const update = {
            dateHour: dateHour
        }
        axios.patch(`https://liga-taca-isep-api.herokuapp.com/api/games/schedule/${values.game[1]}`,
            update,
            config
        )
            .then((response) => {
                form.resetFields();
                setGame('');
                setGames([]);
                setPlatform('');
                message.success('Jogo atualizado com sucesso!');
                console.log(response);
            })
            .catch((error) => {
                message.error('Ocorreu um erro ao atualizar o jogo!');
                console.error(error);
            })
    }

    return (
        <div className="centered-form">
            <Form className="max-width" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É necessário selecionar a fase! ' }]}>
                    <Select
                        placeholder="Selecione a fase"
                        allowClear
                        onChange={setPlatform}
                    >
                        <Option value="PC">
                        Fase Grupos
                        </Option>
                        <Option value="PS">
                        Fase Final
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="game" label="Jogo" rules={[{ required: true, message: 'É necessário selecionar um jogo!' }]}>
                    <Select
                        placeholder="Selecione o jogo"
                        onChange={setGame}
                    >
                        {
                            games.map((game) => (
                                <Option key={game.id} value={[game.player1 + " vs " + game.player2, game.id, game.dateHour]}>
                                    {game.player1} vs {game.player2}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="dateHour" label="Data/Hora do Jogo" rules={[{ required: true, message: 'É necessário selecionar uma data/hora do jogo!' }]}>
                    <DatePicker showTime={{ format: 'HH:mm' }} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Remarcar Jogo
                    </Button>
                </Form.Item>
            </Form>
            <p className="disclaimer-text">
                <span className="bold-text">Data/Hora original: </span>
                <span className="regular-text">{game[2]}</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    )
}

export default RescheduleGame;