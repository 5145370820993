import React, { useEffect, useState } from 'react';
import '../assets/css/Rating.css';
import 'antd/es/style/themes/default.less';
import 'antd/dist/antd.css';
import { message, Table } from 'antd';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FaPlaystation } from 'react-icons/fa';
import { RiComputerLine } from 'react-icons/ri';
import axios from 'axios';

async function fetchGameData(platform) {
  return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/games/active/${platform}`);
}

function Rating() {
  const [headerInfo, setHeaderInfo] = useState([
    'Nome',
    'Nº Jogos',
    'Vitórias',
    'Empates',
    'Derrotas',
    'Golos Marcados',
    'Golos Sofridos',
    'Diferença de Golos',
    'Pontos'
  ]);

  const columns = [
    {
      title: headerInfo[0],
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: "20%",
      className: 'bold-text'
    },
    {
      title: headerInfo[1],
      dataIndex: 'games',
      key: 'games',
      align: 'center'
    },
    {
      title: headerInfo[2],
      dataIndex: 'wins',
      key: 'wins',
      align: 'center'
    },
    {
      title: headerInfo[3],
      dataIndex: 'draws',
      key: 'draws',
      align: 'center'
    },
    {
      title: headerInfo[4],
      dataIndex: 'losses',
      key: 'losses',
      align: 'center'
    },
    {
      title: headerInfo[5],
      dataIndex: 'goalsScored',
      key: 'goalsScored',
      align: 'center'
    },
    {
      title: headerInfo[6],
      dataIndex: 'goalsSuffered',
      key: 'goalsSuffered',
      align: 'center'
    },
    {
      title: headerInfo[7],
      dataIndex: 'goalDiff',
      key: 'goalDiff',
      align: 'center'
    },
    {
      title: headerInfo[8],
      dataIndex: 'points',
      key: 'points',
      align: 'center'
    },
  ];

  const spinIcon = <LoadingOutlined className="spin-icon" spin />

  const [dataSource, setDataSource] = useState([]);
  const [platform, setPlatform] = useState('PC');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const topPlayers = [1, 2, 3];

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setHeaderInfo([
        'Nome',
        'J',
        'V',
        'E',
        'D',
        'GM',
        'GS',
        'DG',
        'P'
      ]);
    } else {
      setHeaderInfo([
        'Nome',
        'Nº Jogos',
        'Vitórias',
        'Empates',
        'Derrotas',
        'Golos Marcados',
        'Golos Sofridos',
        'Diferença de Golos',
        'Pontos'
      ]);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}`)
      .then(async (response) => {
        // THIS SHOULD BE DONE IN THE API
        // get all finished games
        const finishedGames = (await fetchGameData(platform)).data.filter((game) => game.status === 1);

        setError(false);
        let key = 0;
        const ratings = response.data.sort((player1, player2) => {
          // sort by points
          if (player1.points < player2.points) return 1;
          if (player1.points > player2.points) return -1;

          // worst piece of code i've ever written
          // you're welcome to fix it :)
          // check which side has won more
          const directConfrontation = finishedGames
            .find((game) => (game.playerId1 === player1.id && game.playerId2 === player2.id) || (game.playerId1 === player2.id && game.playerId2 === player1.id));
          if (directConfrontation && directConfrontation.score1 !== directConfrontation.score2) {
            if (directConfrontation.playerId1 === player1.id) {;
              return (directConfrontation.score1 < directConfrontation.score2 ? 1 : directConfrontation.score1 > directConfrontation.score2 ? -1 : 0);
            }
            return (directConfrontation.score2 < directConfrontation.score1 ? 1 : directConfrontation.score2 > directConfrontation.score1 ? -1 : 0);
          }

          // sort by goal diff
          const diff1 = player1.goalsScored - player1.goalsSuffered;
          const diff2 = player2.goalsScored - player2.goalsSuffered;
          if (diff1 < diff2) return 1;
          if (diff1 > diff2) return -1;

          // sort by goals scored
          if (player1.goalsScored < player2.goalsScored) return 1;
          if (player1.goalsScored > player2.goalsScored) return -1;

          // if same points, sort by games
          // same points but less games shows
          // an opportunity to climb relative
          // to the other player
          if (player1.games < player2.games) return 1;
          if (player1.games > player2.games) return -1;

          // sort by name
          return player1.name.split(' ')[0].localeCompare(player2.name.split(' ')[0]);
        })
          .map((rating) => {
            key++;
            return {
              key: key,
              name: rating.name,
              games: rating.grupo,
              wins: rating.wins,
              draws: rating.draws,
              losses: rating.losses,
              goalsScored: rating.goalsScored,
              goalsSuffered: rating.goalsSuffered,
              goalDiff: rating.goalsScored - rating.goalsSuffered,
              points: rating.points
            }
          });
        setDataSource(ratings);
      })
      .catch((error) => {
        setError(true);
        console.error(`An error has ocurred: ${error}`);
      })
      .finally(() => {
        if (error) {
          message.error({
            content: 'Não há informação disponível de momento. Por favor, tente mais tarde!'
          })
        }
        /* error message will not appear if this one
        is not here so we made it invisible
        :))))))))))))))))))))))))))))))))))) */
        message.error({
          content: '-',
          className: "hidden-message"
        });
        setLoading(false);
      })
  }, [platform, error]);

  return (
    <div id="rating">
      <Table
        className="regular-text rating-table"
        dataSource={dataSource}
        columns={columns}
        onHeaderRow={() => { return { className: "bold-text header-font" } }}
        rowClassName={(record) => topPlayers.includes(record.key) ? 'table-highlighted' : 'table-normal'}
        bordered={true}
        tableLayout="fixed"
        loading={{ indicator: spinIcon, size: "large", spinning: loading }}
        pagination={{ pageSize: "8" }}
      />
    </div>
  );
}

export default Rating;