import React from 'react';

import '../assets/css/Sidebar.css';

import Regulation from '../assets/docs/Regulamento_2024.pdf';

function Sidebar() {
    
    if (false){    //manutenção
        return (
            <div id="btn-group">
                <a href={Regulation} target="_blank" rel="noreferrer">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">REGULAMENTO</span></span></button>
                </a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdBSpxvQ1Jf-jFc_ID3zDbonAoh2oC0NOtkBz56819x7fSVbg/viewform" target="_blank" rel="noreferrer">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">INSCRIÇÕES</span></span></button>
                </a>
                <a href="/dados-individuais">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">SEM EQUIPA</span></span></button>
                </a>
                <a href="/index">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">EQUIPAS</span></span></button>
                </a>
                <a href="/index">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">HORÁRIOS</span></span></button>
                </a>
                <a href="/index">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">RESULTADOS</span></span></button>
                </a>
                <a href="/index">
                    <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">CLASSIFICAÇÃO</span></span></button>
                </a>
            </div>
        );
    }
    return (
        <div id="btn-group">
            <a href={Regulation} target="_blank" rel="noreferrer">
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">REGULAMENTO</span></span></button>
            </a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdBSpxvQ1Jf-jFc_ID3zDbonAoh2oC0NOtkBz56819x7fSVbg/viewform" target="_blank" rel="noreferrer" disabled>
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">INSCRIÇÕES</span></span></button>
            </a>
            <a href="/dados-individuais" disabled>
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">SEM EQUIPA</span></span></button>
            </a>
            <a href="/jogadores">
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">EQUIPAS</span></span></button>
            </a>
            <a href="/horarios" disabled>
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">HORÁRIOS</span></span></button>
            </a>
            <a href="/resultados">
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">RESULTADOS</span></span></button>
            </a>
            <a href="/pontos">
                <button className="btn"><span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">CLASSIFICAÇÃO</span></span></button>
            </a>
        </div>
    );
}

export default Sidebar;