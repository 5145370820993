import React from 'react';

import '../assets/css/Forms.css';

function SemEquipa() {
    return (
        <iframe className="forms"
            src="https://docs.google.com/forms/d/e/1FAIpQLSf2rZ_FBa0lOyFd_Ro9meBHch1SuCH1t3nSD7ADGH2eWTwhbQ/viewform?embedded=true"
            width="640" height="399" frameBorder="0" marginHeight="0" marginWidth="0">A carregar…</iframe>
    );
}

export default SemEquipa;