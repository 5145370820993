import React from 'react';

import '../assets/css/Dropdown.css';


import Regulation from '../assets/docs/Regulamento_2024.pdf';

import { Menu, Dropdown } from 'antd';
import { HiMenu } from 'react-icons/hi';

const menu = (
  <Menu>
    <Menu.Item >
            <a href={Regulation} target="_blank" rel="noreferrer">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">REGULAMENTO</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdBSpxvQ1Jf-jFc_ID3zDbonAoh2oC0NOtkBz56819x7fSVbg/viewform" target="_blank" rel="noreferrer">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">INSCRIÇÕES</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="/dados-individuais">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">SEM EQUIPA</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="/jogadores">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">EQUIPAS</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="/horarios">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">HORÁRIOS</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="/resultados">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">RESULTADOS</span></span>
            </a>
    </Menu.Item>
    <Menu.Item>
            <a href="/pontos">
                <span className="btn__inner"><span className="btn__slide"></span><span className="btn__content">CLASSIFICAÇÃO</span></span>
            </a>
    </Menu.Item>
    
  </Menu>
);

function dropd() {
    return (
    <Dropdown overlay={menu}>

      <a  onClick={e => e.preventDefault()}>
                <span className="btn__inner2"><span className="btn__slide"></span><span className="btn__content">
                <HiMenu size="36px" /></span></span>
            </a>
    </Dropdown>
    )
}
export default dropd;