import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Form, Input, message, Button, Select, List } from 'antd';
import axios from "axios"
import { RiComputerLine } from 'react-icons/ri';
import { FaPlaystation } from 'react-icons/fa';
const { Option } = Select;


const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

function CreateRound() {
    const [edition, setEdition] = useState("");
    const [pcRounds, setPCRounds] = useState([]);
    const [psRounds, setPSRounds] = useState([]);
    const [update, setUpdate] = useState(true);
    const [formRound] = Form.useForm();

    const token = sessionStorage.getItem('token');

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    function handlePlatform(platform) {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/editions/active/${platform}`)
            .then((response) => {
                setEdition(response.data)
            })
            .catch((error) => {
                message.error("Não há edição ativa!");
                console.error(error);
            })
    };

    const onFinish = (values) => {
        const round = {
            number: Number.parseInt(values.round),
            editionId: edition.id
        }

        axios.post(`https://liga-taca-isep-api.herokuapp.com/api/rounds`,
            round,
            config
        )
            .then((response) => {
                formRound.resetFields();
                setUpdate(true);
                message.success('Grupo criada com sucesso!');
                console.log(response);
            }, (error) => {
                message.error('Ocorreu um erro a criar o Grupo!');
                console.log(error);
            });
    };

    useEffect(() => {
        /* javardo but gotta hurry up */
        if (update) {
            axios
                .get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/active/PC`)
                .then((response) => {
                    setPCRounds(response.data.map(round => round.number));
                })
                .catch((error) => {
                    console.error(error);
                })
            axios
                .get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/active/PS`)
                .then((response) => {
                    setPSRounds(response.data.map(round => round.number));
                })
                .catch((error) => {
                    console.error(error);
                })
            setUpdate(false);
        }
    }, [update])

    return (
        <div className="centered-form">            
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA i: </span>
                <span className="regular-text">O grupo ao criar a Equipa vai defenir os dados a ser mostrados na pagina dos '/pontos' e o grupo para os jogos vai defenir os dados a serem mostrados nos '/resultados'. <br /> &emsp;&emsp;&emsp;&emsp;
                                               Por isso é importante que os grupos dos Jogos têm de corresponder ao grupo das Equipas senão fica baralhado!!!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA ii: </span>
                <span className="regular-text">Na Fase Final não existem pontos por isso o grupo quando se cria a equipa não interessa. <br /> &emsp;&emsp;&emsp;&emsp;
                                              O importante para a Fase Final é nesta página criar estes 3 grupos (pré-defenidos): 101(Quartos), 102(Meias) e 103(Final)!!!</span>
            </p>
            <Form className="max-width" {...layout} form={formRound} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É preciso selecionar uma Fase!' }]}>
                    <Select
                        placeholder="Selecionar Fase"
                        allowClear
                        onChange={handlePlatform}
                    >
                        <Option value="PC">Fase Grupos</Option>
                        <Option value="PS">Fase Final</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="round" label="Nº Grupo" rules={[{ required: true, message: 'É preciso adicionar o número do Grupo!' }]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Adicionar Grupo Jogos
                </Button>
                </Form.Item>
            </Form>
            <p className="disclaimer-text">
                <span className="bold-text">Edição ativa: </span>
                <span className="regular-text">{edition.name}</span>
            </p>
            <div className="editions-lists max-width">
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Grupo - Fase Grupos <RiComputerLine size="24px" /></span>}
                    bordered
                    dataSource={pcRounds}
                    renderItem={round => <List.Item className="regular-text">Grupo {round}</List.Item>}
                />
                <List
                    className="one-fifth-width"
                    header={<span className="bold-text">Grupo - Fase Final <FaPlaystation size="24px" /></span>}
                    bordered
                    dataSource={psRounds}
                    renderItem={round => <List.Item className="regular-text">Grupo {round}</List.Item>}
                />
            </div>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>

        </div>


    );
}

export default CreateRound;