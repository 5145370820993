import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import '../../../assets/css/Admin.css';
import { Form, Input, message, Button, Select } from 'antd';
import axios from "axios"

const { Option } = Select;

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

const token = sessionStorage.getItem('token');

const config = {
    headers: { Authorization: `Bearer ${token}` }
};

async function fetchPlayerData(id) {
    return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/${id}`);
}

function UpdateGame() {
    const [form] = Form.useForm();

    const [game, setGame] = useState("");
    const [platform, setPlatform] = useState('');
    const [players, setPlayers] = useState(["Pontuação jogador 1", "Pontuação jogador 2"])
    const [games, setGames] = useState([]);

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/games/active/${platform}`).then(async (response) => {
                const activeGames = await Promise.all(
                    response.data
                        .filter(game => !game.status)
                        .map(async (game) => {

                            const player1 = (await fetchPlayerData(game.playerId1)).data.name.split(' ');
                            const player1Name = player1[0] + " " + player1.pop();
                            const player2 = (await fetchPlayerData(game.playerId2)).data.name.split(' ');
                            const player2Name = player2[0] + " " + player2.pop();

                            return {
                                id: game.id,
                                player1: player1Name,
                                player2: player2Name
                            }
                        }));
                setGames(activeGames);
            })
            .catch((error) => {
                if (platform !== '') {
                    setGames([]);
                    message.error("Não há jogos na edição ativa!");
                    console.error(error);
                }
            })
    }, [platform]);

    function handlePlatform(platform) {
        setPlatform(platform);
    }

    function handleChange(e) {
        setGame(e[3]);
        setPlayers(["Pontuação " + e[1], "Pontuação " + e[2]])
    }

    const onFinish = (values) => {
        form.resetFields();
        setGames([]);

        const update = {
            score1: values.score1,
            score2: values.score2,
        }

        axios.patch(`https://liga-taca-isep-api.herokuapp.com/api/games/${game}`,
            update,
            config
        )
            .then((response) => {
                message.success('Jogo atualizado com sucesso!');
                console.log(response);
            }, (error) => {
                message.error('Ocorreu um erro a atualizar o jogo!');
                console.log(error);
            });
    }

    return (
        <div className="centered-form">
            <Form className="max-width" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="platform" label="Fase" rules={[{ required: true, message: 'É preciso selecionar uma fase!' }]}>
                    <Select
                        placeholder="Selecionar a fase"
                        allowClear
                        onChange={handlePlatform}
                    >
                        <Option value="PC">
                        Fase Grupos
                        </Option>
                        <Option value="PS">
                        Fase Final
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="game" label="Jogo" rules={[{ required: true, message: 'É preciso selecionar um jogo!' }]}>
                    <Select
                        placeholder="Selecionar a jogo a atualizar"
                        allowClear
                        onChange={handleChange}
                    >
                        {games.map((game) => (
                            <Option key={game.id} value={[game.player1 + " vs " + game.player2, game.player1, game.player2, game.id]}>
                                {game.player1} vs {game.player2}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="score1" label={players[0]} rules={[{ required: true, message: 'É preciso introduzir uma pontuação!' }]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item name="score2" label={players[1]} rules={[{ required: true, message: 'É preciso introduzir uma pontuação!' }]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Atualizar jogo
                    </Button>
                </Form.Item>
            </Form >
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    );
}

export default UpdateGame;