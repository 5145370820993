import React, { useEffect, useState } from 'react';
import { Button, message, Select, Table } from 'antd';
import '../assets/css/Results.css';
import 'antd/dist/antd.css';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { TbLetterF, TbLetterG } from 'react-icons/tb';

import { BiFootball } from 'react-icons/bi';

let locale = {
  emptyText: (
    <span>
      <p>
        <span className="ball-span"><BiFootball size="84px" /></span> 
      </p>
      <span className="empty-txt">Escolhe o grupo!</span>
    </span>
  )
};

const { Option } = Select;

async function fetchPlayerData(id) {
  return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/${id}`);
}

function Results() {
  const [headerInfo, setHeaderInfo] = useState([
    'Equipa 1',
    'Resultado',
    'Equipa 2',
    'Data/Hora do Jogo',
    'Grupo ',
    'QUARTOS DE FINAL',
    'MEIA FINAL',
    'Final',
    '',
  ]);

  const manualDataSource = [
    {
        key: '1',
        player1: 'The Last Dance',
        score: '5 - 1',
        player2: 'Morcela FC',
        dateTime: '6/04/2024 - 9h'
    },
    {
        key: '2',
        player1: 'Real Maracujá',
        score: '5 - 3',
        player2: 'Schumickel',
        dateTime: '6/04/2024 - 10h'
    },
    {
        key: '3',
        player1: 'Todo Contenti',
        score: '5 - 3',
        player2: 'Inter de Melão',
        dateTime: '6/04/2024 - 11h'
    },
    {
        key: '4',
        player1: 'Boda',
        score: '3 - 0',
        player2: 'Boda B',
        dateTime: '6/04/2024 - 12h'
    },
    {
        key: '5',
        player1: 'Todo Contenti',
        score: '5 - 3',
        player2: 'PAROLYMPIACUS',
        dateTime: '13/04/2024 - 14h'
    },
    {
        key: '6',
        player1: 'BBB FC',
        score: '2 - 5',
        player2: 'Bar sem Lona',
        dateTime: '13/04/2024 - 15h'
    },
    {
        key: '7',
        player1: 'Socceriri di Potencia',
        score: '3 - 2',
        player2: 'Boda',
        dateTime: '13/04/2024 - 16h'
    },
    {
        key: '8',
        player1: 'The Last Dance',
        score: '4 - 2',
        player2: 'Real Maracujá',
        dateTime: '13/04/2024 - 17h'
    },
    {
        key: '9',
        player1: 'Gaidiatog Spgings',
        score: '7 - 1',
        player2: 'PAROLYMPIACUS',
        dateTime: '20/04/2024 - 14h'
    },
    {
        key: '10',
        player1: 'Socceriri di Potencia',
        score: '8 - 0',
        player2: 'Boda B',
        dateTime: '20/04/2024 - 15h'
    },
    {
        key: '11',
        player1: 'Morcela FC',
        score: '5 - 1',
        player2: 'Real Maracujá',
        dateTime: '20/04/2024 - 16h'
    },
    {
        key: '12',
        player1: 'The Last Dance',
        score: '19 - 1',
        player2: 'Schumickel',
        dateTime: '20/04/2024 - 17h'
    },
    {
        key: '13',
        player1: 'Inter de Melão',
        score: 'vs',
        player2: 'Gaidiatog Spgings',
        dateTime: '18/05/2024'
    },
    {
        key: '14',
        player1: 'BBB FC',
        score: 'vs',
        player2: 'Black Mamba',
        dateTime: '18/05/2024'
    },
    {
        key: '15',
        player1: 'Todo contenti',
        score: 'vs',
        player2: 'Gaidiatog Spgings',
        dateTime: '25/05/2024 - 14h'
    },
    {
        key: '16',
        player1: 'Morcela FC',
        score: 'vs',
        player2: 'Schumickel',
        dateTime: '25/05/2024 - 15h'
    },
    {
        key: '17',
        player1: 'PAROLYMPIACUS',
        score: 'vs',
        player2: 'Inter de Melão',
        dateTime: '25/05/2024 - 16h'
    },
    {
        key: '18',
        player1: 'Bar sem Lona',
        score: 'vs',
        player2: 'Black Mamba',
        dateTime: '25/05/2024 - 17h'
    }
];

  
  const columns = [
    {
      title: headerInfo[0],
      dataIndex: 'player1',
      key: 'player1',
      align: 'center'
    },
    {
      title: headerInfo[1],
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: "15%",
      className: 'bold-text'
    },
    {
      title: headerInfo[2],
      dataIndex: 'player2',
      key: 'player2',
      align: 'center'
    },
    {
      title: headerInfo[3],
      dataIndex: 'dateTime',
      key: 'dateTime',
      align: 'center',
      width: '20%'
    },
  ];

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setHeaderInfo([
        'Equipa 1',
        'R',
        'Equipa 2',
        'Data',
        'G ',
        'Quartos',
        'Meia',
        'Final',
        '',
      ]);
    } else {
      setHeaderInfo([
        'Equipa 1',
        'Resultado',
        'Equipa 2',
        'Data/Hora do Jogo',
        'Grupo ',
        'Quartos de Final',
        'Meia Final',
        'Final',
        '',
      ]);
    }
  }, []);

  const spinIcon = <LoadingOutlined className="spin-icon" spin />

  const [dataSource, setDataSource] = useState([]);
  const [platform, setPlatform] = useState('PC');
  const [rounds, setRounds] = useState([]);
  const [round, setRound] = useState(["Escolhe o Grupo"]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/active/${platform}`)
      .then((response) => {
        setRounds(response.data
          .sort((round1, round2) => round1.number - round2.number)
          .map((round) => { return { id: round.id, number: round.number } }));
      })
      .catch((error) => {
        setError(true);
        console.error(`An error has ocurred: ${error}`);
      })
      .finally(() => {
        setError(false);
        setLoading(false);
      })
  }, [platform]);

  useEffect(() => {
    if (error) {
      /*message.error({
        content: 'Não há informação disponível de momento. Por favor, tente mais tarde!'
      })*/
    }
    /* error message will not appear if this one
    is not here so we made it invisible
    :))))))))))))))))))))))))))))))))))) */
    message.error({
      content: '-',
      className: "hidden-message"
    });
  }, [error]);

  return (
    <div id="results">
      <h1 className='results-header'>Resultados 2024</h1>
      <Table
        className="regular-text results-table"
        dataSource={manualDataSource}
        columns={columns}
        onHeaderRow={() => { return { className: "bold-text header-font" } }}
        bordered={true}
        tableLayout="fixed"
        loading={{ indicator: spinIcon, size: "large", spinning: loading }}
        pagination={true}
        locale={locale}
      />
    </div>
  );
}

export default Results;