import React, { useEffect, useState } from 'react';
import { Button, Input, DatePicker, Form, message, Select } from 'antd';
import axios from 'axios';

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 5,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 11,
        span: 16,
    },
};

const { Option } = Select;

const token = sessionStorage.getItem('token');
const config = {
    headers: { Authorization: `Bearer ${token}` }
}

async function fetchPlayerData(id) {
    return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/players/${id}`);
}

function ChangeGroup() {
    const [form] = Form.useForm();

    const [platform, setPlatform] = useState('');
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState('');

    useEffect(() => {
        axios
            .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}`)
            .then(async (response) => {
                const players = await Promise.all(
                    response.data
                        .sort((player1, player2) => player1.name.split(' ')[0].localeCompare(player2.name.split(' ')[0]))
                        .map(async (player) => {
                            const name = player.name.split(' ');
                            const first = name[0];
                            const last = name.pop();

                            return {
                                player1: player.name,
                                info: player.degree,
                            }
                        }));
                setPlayers(players);
            })
            .catch((error) => {
                if (platform !== '') {
                    setPlayers([]);
                    message.error('Não há equipas na edição ativa!');
                    console.error(error);
                }
            })
    }, [platform]);

    const onFinish = (values) => {
        const degree = values.degree;
        const update = {
            degree: degree
        }
        axios.patch(`https://liga-taca-isep-api.herokuapp.com/api/players/degree/${values.player[1]}`,
            update,
            config
        )
            .then((response) => {
                form.resetFields();
                setPlayer('');
                setPlayers([]);
                setPlatform('');
                message.success('Info atualizada com sucesso!');
                console.log(response);
            })
            .catch((error) => {
                message.error('Ocorreu um erro ao atualizar!');
                console.error(error);
            })
    }

    return (
        <div className="centered-form">
            <Form className="max-width" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="platform" label="Plataforma" rules={[{ required: true, message: 'É necessário selecionar a plataforma! ' }]}>
                    <Select
                        placeholder="Selecione a plataforma"
                        allowClear
                        onChange={setPlatform}
                    >
                        <Option value="PC">
                            PC
                        </Option>
                        <Option value="PS">
                            PS
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="player" label="Nome da Equipa" rules={[{ required: true, message: 'É necessário selecionar uma!' }]}>    
                    <Select
                        placeholder="Selecione a equipa"
                        onChange={setPlayer}
                    >
                        {
                            players.map((player) => (
                                <Option key={player.id} value={[player.player1]}>
                                    {player.player1}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="degree" label="info adicional" rules={[{ required: true, message: 'É preciso adicionar dados do jogador!' }]}>
                <Input />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Alterar info adicional
                    </Button>
                </Form.Item>
            </Form>



            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA: </span>
                <span className="regular-text">É necessário recarregar a página para atualizar as tabelas consoante as mudanças efetuadas noutras tabs!</span>
            </p>
            <p className="disclaimer-text">
                <span className="bold-text red-text">NOTA 2: </span>
                <span className="regular-text">A sessão (de login) expira numa hora. No fim dessa hora, é necessário abrir outra aba com o Admin e voltar a dar login para ter acesso às funcionalidades.</span>
            </p>
        </div>
    )
}

export default ChangeGroup;