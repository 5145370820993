import React, { useEffect, useState } from 'react';
import '../assets/css/Points.css';
import 'antd/es/style/themes/default.less';
import 'antd/dist/antd.css';
import { message, Select, Table } from 'antd';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiFootball } from 'react-icons/bi';
import { RiFootballLine } from 'react-icons/ri';
import axios from 'axios';

let locale = {
  emptyText: (
    <span>
      <p>
        <span className="ball-span"><BiFootball size="84px" /></span> 
      </p>
      <span className="empty-txt">Escolhe um Grupo!</span>
    </span>
  )
};

const { Option } = Select;

async function fetchGameData(platform) {
  return await axios.get(`https://liga-taca-isep-api.herokuapp.com/api/games/active/${platform}`);
}

function Rating() {
  const [headerInfo, setHeaderInfo] = useState([
    'Nome',
    'Nº Jogos',
    'Vitórias',
    'Derrotas',
    'Golos Marcados',
    'Golos Sofridos',
  ]);

  const columns = [
    {
      title: headerInfo[0],
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: "20%",
      className: 'bold-text'
    },
    {
      title: headerInfo[1],
      dataIndex: 'games',
      key: 'games',
      align: 'center'
    },
    {
      title: headerInfo[2],
      dataIndex: 'wins',
      key: 'wins',
      align: 'center'
    },
    /*{
      title: headerInfo[3],
      dataIndex: 'draws',
      key: 'draws',
      align: 'center'
    },*/
    {
      title: headerInfo[4],
      dataIndex: 'losses',
      key: 'losses',
      align: 'center'
    },
    {
      title: headerInfo[5],
      dataIndex: 'goalsScored',
      key: 'goalsScored',
      align: 'center'
    },
    {
      title: headerInfo[6],
      dataIndex: 'goalsSuffered',
      key: 'goalsSuffered',
      align: 'center'
    },
    /*{
      title: headerInfo[7],
      dataIndex: 'goalDiff',
      key: 'goalDiff',
      align: 'center'
    },
    {
      title: headerInfo[8],
      dataIndex: 'points',
      key: 'points',
      align: 'center'
    },*/
  ];

  const spinIcon = <LoadingOutlined className="spin-icon" spin />

  const [dataSource, setDataSource] = useState([]);
  const [platform, setPlatform] = useState('PC');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const topPlayers = [1, 2, 3];
  const [rounds, setRounds] = useState([]);
  const [round, setRound] = useState(["Grupos"]);

  const setGroupInfo = [{title: headerInfo[9]},];
  useEffect(() => {
    if (window.innerWidth <= 900) {
      setHeaderInfo([
        'Nome',
        'J',
        'V',
        'E',
        'D',
        'GM',
        'GS',
        'DG',
        'P',
        'G'
      ]);
    } else {
      setHeaderInfo([
        'Nome',
        'Nº Jogos',
        'Vitórias',
        'Empates',
        'Derrotas',
        'Golos Marcados',
        'Golos Sofridos',
        'Diferença de Golos',
        'Pontos',
        'Grupo'
      ]);
      
    }
  }, []);

  const handleChange = function (round) {
    setRound(`${headerInfo[9]} ${round[1]}`);
    setLoading(true);
    axios
      .get(`https://liga-taca-isep-api.herokuapp.com/api/players/active/${platform}/`)
      .then(async (response) => {
        // THIS SHOULD BE DONE IN THE API
        // get all finished games
        const finishedGames = (await fetchGameData(platform)).data.filter((game) => game.status === 1);

        setError(false);
        let key = 0;
        const ratings = response.data.sort((player1, player2) => {
          // sort by points
          if (player1.points < player2.points) return 1;
          if (player1.points > player2.points) return -1;

          // worst piece of code i've ever written
          // you're welcome to fix it :)
          // check which side has won more
          const directConfrontation = finishedGames
            .find((game) => (game.playerId1 === player1.id && game.playerId2 === player2.id) || (game.playerId1 === player2.id && game.playerId2 === player1.id));
          if (directConfrontation && directConfrontation.score1 !== directConfrontation.score2) {
            if (directConfrontation.playerId1 === player1.id) {;
              return (directConfrontation.score1 < directConfrontation.score2 ? 1 : directConfrontation.score1 > directConfrontation.score2 ? -1 : 0);
            }
            return (directConfrontation.score2 < directConfrontation.score1 ? 1 : directConfrontation.score2 > directConfrontation.score1 ? -1 : 0);
          }

          // sort by goal diff
          const diff1 = player1.goalsScored - player1.goalsSuffered;
          const diff2 = player2.goalsScored - player2.goalsSuffered;
          if (diff1 < diff2) return 1;
          if (diff1 > diff2) return -1;

          // sort by goals scored
          if (player1.goalsScored < player2.goalsScored) return 1;
          if (player1.goalsScored > player2.goalsScored) return -1;

          // if same points, sort by games
          // same points but less games shows
          // an opportunity to climb relative
          // to the other player
          if (player1.games < player2.games) return 1;
          if (player1.games > player2.games) return -1;

          // sort by name
          return player1.name.split(' ')[0].localeCompare(player2.name.split(' ')[0]);
        })
        
        //tem de ser defenido manualemente na base de dados o grupo das equipas/players
        //e tb pode ser manualmente ou pelo admin defenir o grupo/round de cada jogo
        .filter(game => game.grupo === round[1])
          .map((rating) => {
            key++;

            //ao colocar o resultado dos jogos pelo admin acrescenta sempre valores a mais em alguns campos
            //isso acontece pq o codigo esta feito para assumir que se não foram publicados todos os jogos é pq houveram empates 0-0
            //por isso terá de ser feito algumas condiçoes dependendo das edicoes ou só publicar os resultados no fim da liga
            
            
            if(rating.draws===3){  
              if (rating.games ===0) rating.games=2;
              if (rating.draws ===0) rating.draws=2;
              if (rating.points ===0) rating.points=2;

              return {
                key: key,
                name: rating.name,
                games: rating.games-2,
                wins: rating.wins,
                draws: rating.draws-2,
                losses: rating.losses,
                goalsScored: rating.goalsScored,
                goalsSuffered: rating.goalsSuffered,
                goalDiff: rating.goalsScored - rating.goalsSuffered,
                points: rating.points-2
              }
            }


            if(rating.draws===4){  
              if (rating.games ===0) rating.games=2;
              if (rating.draws ===0) rating.draws=2;
              if (rating.points ===0) rating.points=2;

              return {
                key: key,
                name: rating.name,
                games: rating.games-1,
                wins: rating.wins,
                draws: rating.draws-4,
                losses: rating.losses,
                goalsScored: rating.goalsScored,
                goalsSuffered: rating.goalsSuffered,
                goalDiff: rating.goalsScored - rating.goalsSuffered,
                points: rating.points-1
              }
            }
              return {
                key: key,
                name: rating.name,
                games: rating.games,
                wins: rating.wins,
                draws: rating.draws,
                losses: rating.losses,
                goalsScored: rating.goalsScored,
                goalsSuffered: rating.goalsSuffered,
                goalDiff: rating.goalsScored - rating.goalsSuffered,
                points: rating.points
              }
            

          });
        setDataSource(ratings);
      })
      .catch((error) => {
        setDataSource([]);
        console.error(`An error has ocurred: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://liga-taca-isep-api.herokuapp.com/api/rounds/active/${platform}`)
      .then((response) => {
        setRounds(response.data
          .sort((round1, round2) => round1.number - round2.number)
          .map((round) => { return { id: round.id, number: round.number } }));
      })
      .catch((error) => {
        setError(true);
        console.error(`An error has ocurred: ${error}`);
      })
      .finally(() => {
        setError(false);
        setLoading(false);
      })
  }, [platform]);

  useEffect(() => {
    if (error) {
      /*message.error({
        content: 'Não há informação disponível de momento. Por favor, tente mais tarde!'
      })*/
    }
    /* error message will not appear if this one
    is not here so we made it invisible
    :))))))))))))))))))))))))))))))))))) */
    message.error({
      content: '-',
      className: "hidden-message"
    });
  }, [error]);

  return (
    <div id="results">
      <div id="table-btn-group">
        <Select id="select-btn" value={round} onChange={handleChange}>
          {
            rounds.map((round) => (
              <Option key="1" className="regular-text" value="Equipas" >
                Equipas
              </Option>
            ))
          }
        </Select>
      </div>
      <Table
        className="regular-text rating-table"
        dataSource={dataSource}
        columns={columns}
        onHeaderRow={() => { return { className: "bold-text header-font" } }}
        rowClassName={(record) => topPlayers.includes(record.key) ? 'table-highlighted' : 'table-normal'}
        bordered={true}
        tableLayout="fixed"
        loading={{ indicator: spinIcon, size: "large", spinning: loading }}
        pagination={false}
        locale={locale}
      />
    </div>
  );
}

export default Rating;