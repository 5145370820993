import React from 'react';
import '../assets/css/Homepage.css';
import 'antd/dist/antd.css';
import cartaz from "../assets/img/cartaz.png";
import placeholder from "../assets/img/placeholder.jpg";

function Homepage() {
    return (
            //<img src={cartaz} className="poster" alt="Liga & Taça ISEP - Cartaz" />
            <img src={placeholder} className="poster" alt="Liga & Taça ISEP" />
    );
}

export default Homepage;